import { render, staticRenderFns } from "./AskModal.vue?vue&type=template&id=be9d4412"
import script from "../controllers/AskModal.ts?vue&type=script&lang=ts&external"
export * from "../controllers/AskModal.ts?vue&type=script&lang=ts&external"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "../i18n/AskModal.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fhome%2Fgitlab-runner%2Fbuilds%2FrjjQxtxu%2F0%2Femasofts%2Faudit-paie%2Fneko-paie%2Ffrontend%2Fsrc%2Fcomponents%2FaskModal%2Fvues%2FAskModal.vue&external"
if (typeof block0 === 'function') block0(component)

export default component.exports