import { render, staticRenderFns } from "./DataList.vue?vue&type=template&id=6841b552&scoped=true"
import script from "./DataList.vue?vue&type=script&lang=ts"
export * from "./DataList.vue?vue&type=script&lang=ts"
import style0 from "./DataList.vue?vue&type=style&index=0&id=6841b552&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6841b552",
  null
  
)

/* custom blocks */
import block0 from "../i18n/DataList.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fhome%2Fgitlab-runner%2Fbuilds%2FrjjQxtxu%2F0%2Femasofts%2Faudit-paie%2Fneko-paie%2Ffrontend%2Fsrc%2Fcomponents%2FdataList%2Fvues%2FDataList.vue&external"
if (typeof block0 === 'function') block0(component)

export default component.exports